import React, { Component } from 'react'

export class Slider extends Component {

  render() {
    return (
        <React.Fragment>
			<div className="img-fluid slider" style={{"background": "url('/images/slider-bg.jpg')"}}>
				<div  className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center">
					<div className="container">
						<div className="row justify-content-start">
							<div className="col-10 col-lg-8">
								<h2 className="font-family-paytone-one slider-heading display-3 text-white wow  slideInDown mb-4 animated" style={{"visibility": "visible"}}>
								We are making international <br />education accessible <br />to everyone.</h2>
								<p className="fs-5 fw-medium text-white mb-4 pb-2 d-none d-sm-block slider-below-text d-md-block d-lg-block">
									Discover the ideal destination for your study and career <br />aspirations! Trust the leading visa advisors to assist you in <br />preparing your student visa application.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
        </React.Fragment>
    )
  }
}

export default Slider