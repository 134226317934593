import React, { Component } from 'react'

export class RecentVisaSuccess extends Component {
  render() {
    return (
        <div className="bg-orange">
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-12 text-white text-center fs-2 fw-bold mb-4">
                        Recent Visa Success
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="rec-vis-suc-1 text-center bg-blue pb-3">
                            <img src="/images/visa-success.jpg" className="img-fluid suc-vis-img" />
                            <div className="rec-vis-suc-1-text text-center fs-4 fw-bold text-white mt-2">Neha Navadiya</div>
                            <div className="rec-vis-suc-1-text text-center fs-5 text-white">Canada Visa</div>
                        </div>
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="rec-vis-suc-1 text-center bg-blue pb-3">
                            <img src="/images/visa-success.jpg" className="img-fluid suc-vis-img" />
                            <div className="rec-vis-suc-1-text text-center fs-4 fw-bold text-white mt-2">Neha Navadiya</div>
                            <div className="rec-vis-suc-1-text text-center fs-5 text-white">Canada Visa</div>
                        </div>
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="rec-vis-suc-1 text-center bg-blue pb-3">
                            <img src="/images/visa-success.jpg" className="img-fluid suc-vis-img" />
                            <div className="rec-vis-suc-1-text text-center fs-4 fw-bold text-white mt-2">Neha Navadiya</div>
                            <div className="rec-vis-suc-1-text text-center fs-5 text-white">Canada Visa</div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
  }
}

export default RecentVisaSuccess