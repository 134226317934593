import React from 'react';
import { Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css"
import './App.css';
import ScrollToTop from './components/ScrollToTop'
import GlobalAnimationInitialize from './components/GlobalAnimationInitialize'
import Spinner from './components/Spinner'
import Header from './components/Header'
import Home from './pages/Home'
import Footer from './components/Footer'

export class App extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            loader: 'true'
        }
    }
    changeStateOfLoader = (loader) => {
        this.setState({
            loader: loader
        });
    }
	componentDidMount() {
		this.changeStateOfLoader(true)
		setTimeout(() => {
			this.changeStateOfLoader(false)
		}, 1000);
	}
	render() {
		return (
			<React.Fragment>
				{ this.state.loader ? <Spinner /> : null}
				<GlobalAnimationInitialize />
				<ScrollToTop />
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
				</Routes>
				<Footer />
			</React.Fragment>
		)
	}
}

export default App;
