import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import WOW from 'wowjs'
// It was added for animation for blocks on page change
export default function GlobalAnimationInitialize() {
	const { pathname } = useLocation();

	useEffect(() => {
		new WOW.WOW({
			live: false
		}).init();
	}, [pathname]);

	return null;
}