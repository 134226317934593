import React, { Component } from 'react'

export class Statistics extends Component {
  render() {
    return (
      <div className="img-fluid statistics" style={{"background": "url('/images/stat-bg.jpg')"}}>
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-12 text-secondary text-center fs-2 fw-bold">
                        Our Statistics
                    </div>
                    <div className="col-md-4">
                        <div className="stat-1 bg-orange rounded-3 p-4 my-4 text-center">
                            <div className="display-2 fw-bold d-flex align-items-center justify-content-center text-white mt-4">10+</div>
                        </div>
                        <div className="stat-hr w-50 mx-auto mt-4"></div>
                        <div className="fw-bold h3 text-center mt-4">Countries</div>
                    </div>
                    <div className="col-md-4">
                        <div className="stat-1 bg-red rounded-3 p-4 my-4 text-center">
                            <div className="display-2 fw-bold d-flex align-items-center justify-content-center text-white mt-4">150+</div>
                        </div>
                        <div className="stat-hr w-50 mx-auto mt-4"></div>
                        <div className="fw-bold h3 text-center mt-4">Universities</div>
                    </div>
                    <div className="col-md-4">
                        <div className="stat-1 bg-blue rounded-3 p-4 my-4 text-center">
                            <div className="display-2 fw-bold d-flex align-items-center justify-content-center text-white mt-4">35+</div>
                        </div>
                        <div className="stat-hr w-50 mx-auto mt-4"></div>
                        <div className="fw-bold h3 text-center mt-4">Supportive Staff</div>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}

export default Statistics