import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Header.css';
export class NavbarFront extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenMenu: false
        }
    }
    toggleNavbar = () => {
        this.setState({
            isOpenMenu: !this.state.isOpenMenu
        });
    };
    closeNavbar = () => {
        if (window.innerWidth <= 768) {
            this.setState({ isOpenMenu: false });
        }
    };
  render() {
    return (
        <React.Fragment>
            <div className="bg-white shadow-lg">
                <div className="container">
                    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <Link to={"/"} className="navbar-brand" onClick={ this.closeNavbar }>
                                <img src="/images/logo.png" alt="Seven High Pre School" />
                            </Link>
                            <Link to={"/"} className="admission-now-btn-header btn text-white rounded-pill px-3 d-block d-sm-none m-2">Book Counselling<i className="bi bi-bookmark-plus-fill"></i></Link>
                            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" onClick={ this.toggleNavbar }>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className={`collapse navbar-collapse ${this.state.isOpenMenu ? 'show' : ''}`} id="navbarCollapse">
                            <div className="navbar-nav mx-auto">
                                <div className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><strong>Study </strong>in <strong><i className="bi bi-chevron-double-down"></i></strong></Link>
                                    <div className="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
                                        <Link to={"/about-us"} className="dropdown-item" onClick={ this.closeNavbar }>About Us</Link>
                                        <Link to={"/management-message"} className="dropdown-item" onClick={ this.closeNavbar }>Management Message</Link>
                                        <Link to={"/principal-message"} className="dropdown-item" onClick={ this.closeNavbar }>Principal Message</Link>
                                        <Link to={"/incharge-message"} className="dropdown-item" onClick={ this.closeNavbar }>Incharge Message</Link>
                                        <Link to={"/branches"} className="dropdown-item" onClick={ this.closeNavbar }>Branches</Link>
                                        <Link to={"/testimonial"} className="dropdown-item" onClick={ this.closeNavbar }>Testimonial</Link>
                                        <Link to={"/prospectus"} className="dropdown-item" onClick={ this.closeNavbar }>Prospectus</Link>
                                        <Link to={"/images/brochure/brochure.pdf"} className="dropdown-item" target="_blank" onClick={ this.closeNavbar }>Brochure</Link>
                                    </div>
                                </div>
                                <div className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><strong>Services</strong> <strong><i className="bi bi-chevron-double-down"></i></strong></Link>
                                    <div className="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
                                        <Link to={"/school-facilities"} className="dropdown-item" onClick={ this.closeNavbar }>School Facilities</Link>
                                        <Link to={"/curriculam"} className="dropdown-item" onClick={ this.closeNavbar }>Curriculam</Link>
                                    </div>
                                </div>
                                <div className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><strong>About </strong>Us <strong><i className="bi bi-chevron-double-down"></i></strong></Link>
                                    <div className="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
                                        <Link to={"/admission-criteria"} className="dropdown-item" onClick={ this.closeNavbar }>Admission Criteria</Link>
                                        <Link to={"/admission-inquiry"} className="dropdown-item" onClick={ this.closeNavbar }>Admission Inquiry</Link>
                                        <Link to={"/fees-payment"} className="dropdown-item" onClick={ this.closeNavbar }>Fees Payment</Link>
                                    </div>
                                </div>
                                <div className="nav-item dropdown">
                                    <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><strong>Contact </strong> us <strong><i className="bi bi-chevron-double-down"></i></strong></Link>
                                    <div className="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
                                        <Link to={"/achievements"} className="dropdown-item" onClick={ this.closeNavbar }>Achievements</Link>
                                        <Link to={"/competition"} className="dropdown-item" onClick={ this.closeNavbar }>Competition</Link>
                                        <Link to={"/annual-function"} className="dropdown-item" onClick={ this.closeNavbar }>Annual Function</Link>
                                    </div>
                                </div>
                            </div>
                            <Link to={"/admission-inquiry"} className="admission-now-btn-header btn text-white px-3 d-none d-lg-block"><strong>Book Counselling</strong></Link>
                        </div>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
  }
}

export default NavbarFront