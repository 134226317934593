import React, { Component } from 'react'

export class StudyDestination extends Component {
  render() {
    return (
        <div className="bg-light-blue">
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-12 text-blue text-center fs-2 fw-bold mb-4">
                        Study Destination
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="std-dest-1 text-center" style={{"background": "url('/images/ca.jpg')"}}>
                            <div className="std-dest-1-text text-center p-2 bg-white d-inline position-absolute fs-4 fw-bold text-blue">Canada</div>
                        </div>
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="std-dest-1 text-center" style={{"background": "url('/images/usa.jpg')"}}>
                            <div className="std-dest-1-text text-center p-2 bg-white d-inline position-absolute fs-4 fw-bold text-blue">USA</div>
                        </div>
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="std-dest-1 text-center" style={{"background": "url('/images/uk.jpg')"}}>
                            <div className="std-dest-1-text text-center p-2 bg-white d-inline position-absolute fs-4 fw-bold text-blue">UK</div>
                        </div>
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="std-dest-1 text-center" style={{"background": "url('/images/au.jpg')"}}>
                            <div className="std-dest-1-text text-center p-2 bg-white d-inline position-absolute fs-4 fw-bold text-blue">Australia</div>
                        </div>
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="std-dest-1 text-center" style={{"background": "url('/images/nz.jpg')"}}>
                            <div className="std-dest-1-text text-center p-2 bg-white d-inline position-absolute fs-4 fw-bold text-blue">New zealand</div>
                        </div>
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="std-dest-more text-center ">
                            <div className="d-flex align-items-center justify-content-center mt-4">
                            <img src="/images/ff.png" className="img-fluid" />
                            </div>
                            <div className="text-center p-2 fw-bold text-blue display-4">& Many<br />More</div>
                        </div>

                    </div>
                </div>
            </div>
    </div>
    )
  }
}

export default StudyDestination