import React, { Component } from 'react'
import Slider from '../components/Slider'
import Statistics from '../components/Statistics'
import StudyDestination from '../components/StudyDestination'
import RecentVisaSuccess from '../components/RecentVisaSuccess'

export class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Slider />
        <Statistics />
        <StudyDestination />
        <RecentVisaSuccess />
      </React.Fragment>
    )
  }
}

export default Home