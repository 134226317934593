import React, { Component } from 'react'
import './Spinner.css';
// import t_logo from './images/t_logo.png'
export class Spinner extends Component {
  render() {
    return (
        <div id="my-spinner" className="bg-purple-opacity bg-opacity-75 position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div style={{width: "3rem", height: "3rem"}}>
                <span className="sr-only"><img className="logo" src="/images/logo.png" alt="" /></span>
            </div>
        </div>
    )
  }
}

export default Spinner