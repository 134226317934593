import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Footer.css';
export class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid bg-blue text-white-50 footer pt-5">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h3 className="text-white mb-4 font-family-paytone-one wow fadeInUp" data-wow-delay="0.1s">About</h3>
                        <p className="wow fadeInUp" data-wow-delay="0.3s">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-outline-light btn-social wow fadeInUp" data-wow-delay="0.1s" href=""><i className="bi bi-twitter"></i></a>
                            <a className="btn btn-outline-light btn-social wow fadeInUp" data-wow-delay="0.3s" href=""><i className="bi bi-facebook"></i></a>
                            <a className="btn btn-outline-light btn-social wow fadeInUp" data-wow-delay="0.5s" href=""><i className="bi bi-youtube"></i></a>
                            <a className="btn btn-outline-light btn-social wow fadeInUp" data-wow-delay="0.7s" href=""><i className="bi bi-linkedin"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="text-white mb-4 font-family-paytone-one wow fadeInUp" data-wow-delay="0.1s">Quick Links</h3>
                        <Link className="btn btn-link text-white-50 text-decoration-none" to={"/"}><i className="bi bi-caret-right"></i>Study In</Link>
                        <Link className="btn btn-link text-white-50 text-decoration-none" to={"/"}><i className="bi bi-caret-right"></i>Services</Link>
                        <Link className="btn btn-link text-white-50 text-decoration-none" to={"/"}><i className="bi bi-caret-right"></i>About Us</Link>
                        <Link className="btn btn-link text-white-50 text-decoration-none" to={"/"}><i className="bi bi-caret-right"></i>Contact Us</Link>
                        <Link className="btn btn-link text-white-50 text-decoration-none" to={"/"}><i className="bi bi-caret-right"></i>Book Counselling</Link>
                        <Link className="btn btn-link text-white-50 text-decoration-none" to={"/"}><i className="bi bi-caret-right"></i>Terms &amp; Condition</Link>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="text-white mb-4 font-family-paytone-one wow fadeInUp" data-wow-delay="0.1s">Contact Info</h3>
                        <p className="mb-2"><i className="bi bi-geo-alt wow fadeInUp" data-wow-delay="0.1s"></i> Address Here</p>
                        <p className="mb-2"><i className="bi bi-whatsapp wow fadeInUp" data-wow-delay="0.3s"></i> (+91)9879626666</p>
                        <p className="mb-2"><i className="bi bi-phone wow fadeInUp" data-wow-delay="0.5s"></i> (+91)9879636666</p>
                        <p className="mb-2"><i className="bi bi-envelope-at wow fadeInUp" data-wow-delay="0.7s"></i> info@sevencountry.com</p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="text-white mb-4 font-family-paytone-one wow fadeInUp" data-wow-delay="0.1s">Get In Touch With Us</h3>
                        <p className="wow fadeInUp" data-wow-delay="0.3s">Fill up the form now!</p>
                        <Link to={"/"} className="btn bg-green py-2 mt-2 text-white wow fadeInUp" data-wow-delay="0.3s">Contact Now</Link>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row wow fadeInUp" data-wow-delay="0.3s">
                        <div className="col-md-12">
                            <hr className="bg-light border-2 border-top border-light" />
                        </div>
                        <div className="col-md-6 text-center text-md-start mb-3">
							Designed with <i className="bi bi-heart-fill text-white"></i> By Socialam
                        </div>
                        <div className="col-md-6 text-center text-md-end mb-3">
                        Copyright © 2023. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Footer